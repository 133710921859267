<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
     <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Slider Deleted List
            <v-spacer></v-spacer>
            <!-- <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col> -->
          </v-card-title>
          <div v-if="user.length>0">
            <v-layout wrap pt-10 pb-10>
              <v-flex v-for="(item, i) in user" :key="i" md3 pa-4>
                <v-card style="line-height: 16px" tile flat>
                  <div v-viewer style="display: flex">
                    <img
                      style="width: 100%; object-fit: contain; cursor: pointer"
                      :src="baseURL + item.image"
                      height="300px"
                    />
                  </div>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FF2323"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-layout wrap justify-center px-2>
                    <v-flex pt-2 xl12 md12 lg12 xs12 sm12>
                      <span style="font-size: 13px; color: #000000">
                        {{ item.link }} </span
                      ><br /><br />
                      <span v-if="item.ishighlight==true" style="font-size: 13px; font-weight:bold; color: #000000">
                       Main Image
                      </span>
                       <span v-else style="font-size: 13px; font-weight:bold; color: #000000">
                       Sub Image
                      </span>
                    </v-flex>
                    <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                      <v-dialog v-model="item.delete" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            class="mr-2"
                            outlined
                            v-on="on"
                            v-bind="attrs"
                            color="red"
                          >
                            Activate
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to activate this
                            Slider?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="item.delete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItem(item)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <br />
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
           <div v-else>
            <v-layout pt-15 justify-center fill-height wrap>
              <v-flex text-center xs12 lg12>
                <span
                  style="
                    font-size: 22px;
                    letter-spacing: 1px;
                  "
                >
                  No Data Found...
                </span>
              </v-flex>
            </v-layout>
          </div>
          <!-- <div class="pt-2">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
            ></v-pagination>
          </div> -->
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// const MAX_SIZE = 300000;
const MAX_WIDTH = 800;
const MAX_HEIGHT = 1200;
export default {
  data() {
    return {
      checkbox: true,
      ServerError: false,
      file: null,
      showPreview: false,
      imagePreview: null,
      showsnackbar: false,
      editingitem: [],
      msg: null,
      pages: 0,
      link: "",
      widthOfCard: "250px",
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      ratings: [],
      dialog: false,
      editdialog: false,
      user: [],
      addslider: false,
      imgId: "",
      image: {
        size: "",
        height: "",
        width: "",
      },
      flag: false,
      imageError: "",
      rules: {
        required: (value) =>
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
            value
          ) || "Must be link",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/exclusive/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          status:"Deleted",
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/home/slider/activate",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Activate Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.$router.push("/home/sliderList");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
    },
    showImage(e) {
      this.imageError = "";
      // var files=e.target.files
      this.file = e.target.files[0];
      // if (!this.file || this.file.type.indexOf("image/") !== 0) return;
      // this.image.size = this.file.size;
      // if (this.image.size > MAX_SIZE) {
      //   this.imageError = `The image size (${this.image.size}) is too much (max is ${MAX_SIZE}).`;
      //   this.flag = true;
      //   return;
      // } else this.flag = false;
      var reader = new FileReader();
      reader.onload = (e) => {
        var img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          // console.log(this.image);
          if (this.image.width > MAX_WIDTH) {
            this.imageError = `The image width (${this.image.width}) is too much (max is ${MAX_WIDTH}).`;
            this.flag = true;
            return;
          } else this.flag = false;
          if (this.image.height > MAX_HEIGHT) {
            this.imageError = `The image height (${this.image.height}) is too much (max is ${MAX_HEIGHT}).`;
            this.flag = true;
            return;
          } else this.flag = false;
        };
        this.imagePreview = e.target.result;
        this.flag = true;
      };
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.file);

      axios({
        method: "POST",
        url: "/home/slider/uploadimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
  },
};
</script>